<template>
  <page-layout :has-bindings="hasBindings" :use-match-height="false">
    <v-col cols="12" lg="6">
      <bind-address-card></bind-address-card>
    </v-col>
    <v-col cols="12" lg="6">
      <current-addresses-card></current-addresses-card>
    </v-col>
  </page-layout>
</template>

<script>
import PageLayout from "../../components/Layouts/PageLayout";
import BindAddressCard from "./components/AddBindingCard";
import CurrentAddressesCard from "./components/CurrentBindingsCard";
import { mapState } from "vuex";

export default {
  name: "Addresses",
  components: { CurrentAddressesCard, PageLayout, BindAddressCard },
  computed: {
    ...mapState("user", ["bindings"]),
    hasBindings() {
      return this.bindings.length > 0;
    },
  },
};
</script>

<style scoped></style>
