<template>
  <simple-card>
    <template v-slot:title-left>Добавить адрес</template>
    <template v-slot:content>
      <v-stepper v-model="step" vertical>
        <v-stepper-step :complete="step > 1" step="1">
          Лицевой счет
          <small>Номер привязываемого ЛС</small>
        </v-stepper-step>

        <v-stepper-content step="1" class="text-left">
          <v-text-field
            v-model="ls"
            class="bind-card-input"
            outlined
            dense
            placeholder="Лицевой счет"
          ></v-text-field>
          <v-combobox
            v-if="ukArr.length > 1"
            label="Управляющая компания"
            v-model="uk"
            :items="ukArr"
            item-text="name"
            item-value="id"
          ></v-combobox>
          <v-btn
            small
            :disabled="disabledCheckLs"
            color="primary"
            :loading="loading"
            @click="checkLsPrepare()"
          >
            Далее
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 2" step="2">
          Выберите способ привязки
        </v-stepper-step>

        <v-stepper-content step="2" class="text-left">
          <v-radio-group v-model="chosenWayId" class="pl-2">
            <v-radio
              :style="{ 'background-color': 'none' }"
              v-for="way in bindingWays"
              :key="way.text"
              :label="way.text"
              :value="way.code"
            ></v-radio>
          </v-radio-group>
          <v-btn small color="primary" dense @click="choseBindingWay()">
            Далее
          </v-btn>
          <v-btn small @click="step = 1" dense text> Назад </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="step > 3" step="3">
          Укажите контрольные данные
        </v-stepper-step>

        <v-stepper-content v-if="chosenWay" step="3" class="text-left">
          <p>{{ chosenWay.description }}</p>
          <v-text-field
            v-model="answer"
            class="bind-card-input"
            outlined
            dense
          ></v-text-field>
          <v-btn
            small
            color="primary"
            dense
            :disabled="!answer"
            :loading="loading"
            @click="bindLs()"
          >
            Привязать
          </v-btn>
          <v-btn small @click="step = 2" dense text> Назад </v-btn>
        </v-stepper-content>
      </v-stepper>
    </template>
  </simple-card>
</template>

<script>
import SimpleCard from "../../../components/cards/SimpleCard";
import UserService from "../../../services/UserService";
import store from "../../../store";

export default {
  name: "AddBindingCard",
  components: { SimpleCard },
  data() {
    return {
      ukArr: [],
      bindingWays: null,
      chosenWayId: null,
      chosenWay: null,
      answer: null,
      step: 1,
      ls: "",
      uk: null,
      loading: false,
      prepared: false,
    };
  },
  computed: {
    disabledCheckLs() {
      return !this.ls;
    },
  },
  methods: {
    async checkLsPrepare() {
      if (this.prepared) {
        return await this.checkLs();
      }
      try {
        this.loading = true;
        this.ukArr = (await UserService.checkLsPrepare(this.ls)) || [];
        this.uk = this.ukArr[0];
        if (this.ukArr.length <= 1) {
          await this.checkLs();
        } else {
          this.prepared = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async checkLs() {
      try {
        this.loading = true;
        this.bindingWays = await UserService.checkLs(this.ls, this.uk?.id);
        this.step = 2;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    choseBindingWay() {
      this.chosenWay = this.bindingWays[this.chosenWayId];
      this.step = 3;
    },
    async bindLs() {
      const payload = {
        number: this.ls,
        code: this.chosenWayId,
        answer: this.answer,
        uk: this.uk,
      };

      this.loading = true;
      const result = await UserService.bindLs(payload);
      this.loading = false;

      if (result) {
        store.commit("app/setAlert", {
          shown: true,
          text: "Лицевой счет успешно добавлен",
          color: "success",
        });
        this.step = 1;
        this.ls = "";
        this.answer = "";
        this.$router.push({ name: "home" }).then(() => {});
      }
    },
  },
  watch: {
    ls() {
      this.ukArr = [];
      this.step = 1;
      this.prepared = false;
    },
    bindingWays(newWays) {
      this.chosenWayId = Object.values(newWays)[0]?.code;
    },
    chosenWayId() {
      this.chosenWay = this.bindingWays
        ? this.bindingWays[this.chosenWayId]
        : null;
    },
  },
};
</script>

<style>
.bind-card-input {
  max-width: 250px;
}
</style>
