<template>
  <simple-card>
    <template v-slot:title-left>Текущие привязки</template>
    <template v-slot:content>
      <current-bindings-table></current-bindings-table>
    </template>
  </simple-card>
</template>

<script>
import SimpleCard from "../../../components/cards/SimpleCard";
import CurrentBindingsTable from "./CurrentBindingsTable";

export default {
  name: "CurrentBindingsCard",
  components: { SimpleCard, CurrentBindingsTable },
};
</script>

<style scoped></style>
